<template>
    <div
        class="dark:bg-gray-800 min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
    >
        <Loader />
    </div>
</template>

<script>
import firebase from 'firebase/app'
import { useRouter } from 'vue-router'

import Loader from '@/components/Loader'

import errorHandler from '@/helpers/errorHandler'

export default {
    components: {
        Loader,
    },

    setup() {
        const router = useRouter()

        const signOut = async () => {
            try {
                await firebase.auth().signOut()
                router.push({ name: 'Auth' })
            } catch (error) {
                errorHandler(error)
            }
        }
        signOut()
    },
}
</script>
